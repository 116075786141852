import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((module) => module.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../../dashboard-app/src/app/home/home.module').then(
        (module) => module.HomeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'enterprise/dashboard',
    loadChildren: () =>
      import('../../../dashboard-app/src/app/home/home.module').then(
        (module) => module.HomeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-user',
    // loadChildren: () =>
    //   import('../../../manage-user-app/src/app/sample/sample.module').then(
    //     (module) => module.SampleModule
    //   ),
    children: [
      {
        path: 'calls',
        loadChildren: () =>
          import('../../../manage-user-app/src/app/call/call.module').then(
            (m) => m.CallModule
          ),
      },
      {
        path: 'enquiries',
        loadChildren: () =>
          import(
            '../../../manage-user-app/src/app/enquiry/enquiry.module'
          ).then((m) => m.EnquiryModule),
      },
      {
        path: 'in-clinic-appointments',
        loadChildren: () =>
          import(
            '../../../manage-user-app/src/app/in-clinic-appointment/in-clinic-appointment.module'
          ).then((m) => m.InClinicAppointmentModule),
      },
      {
        path: 'video-consultations',
        loadChildren: () =>
          import(
            '../../../manage-user-app/src/app/video-consultation/video-consultation.module'
          ).then((m) => m.VideoConsultationModule),
      },
      {
        path: 'newsletter-subscriptions',
        loadChildren: () =>
          import(
            '../../../manage-user-app/src/app/newsletter-subscription/newsletter-subscription.module'
          ).then((m) => m.NewsletterSubscriptionModule),
      },
      {
        path: 'bookings',
        loadChildren: () =>
          import(
            '../../../manage-user-app/src/app/booking/booking.module'
          ).then((m) => m.BookingModule),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('../../../manage-user-app/src/app/order/order.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'manage-appointments',
        loadChildren: () =>
          import(
            '../../../manage-user-app/src/app/manage-appointment/manage-appointment.module'
          ).then((m) => m.ManageAppointmentModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-content',
    loadChildren: () =>
      import('../../../manage-content-app/src/app/app.module').then(
        (module) => module.AppModule
      ),
    // children: [
    //   {
    //     path: 'product-catalogue',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/product-catalogue/product-catalogue.module'
    //       ).then((m) => m.ProductCatalogueModule),
    //   },
    //   {
    //     path: 'room-catalogue',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/product-catalogue/product-catalogue.module'
    //       ).then((m) => m.ProductCatalogueModule),
    //   },
    //   {
    //     path: 'all-service-catalogue',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/product-catalogue/product-catalogue.module'
    //       ).then((m) => m.ProductCatalogueModule),
    //   },
    //   {
    //     path: 'item-catalogue',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/product-catalogue/product-catalogue.module'
    //       ).then((m) => m.ProductCatalogueModule),
    //   },
    //   {
    //     path: 'courses-catalogue',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/product-catalogue/product-catalogue.module'
    //       ).then((m) => m.ProductCatalogueModule),
    //   },
    //   {
    //     path: 'real-estate-catalogue',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/product-catalogue/product-catalogue.module'
    //       ).then((m) => m.ProductCatalogueModule),
    //   },
    //   {
    //     path: 'service-catalogue',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/service-catalogue/service-catalogue.module'
    //       ).then((m) => m.ServiceCatalogueModule),
    //   },
    //   {
    //     path: 'latest-updates',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/latest-update/latest-update.module'
    //       ).then((m) => m.LatestUpdateModule),
    //   },
    //   {
    //     path: 'image-gallery',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/image-gallery/image-gallery.module'
    //       ).then((m) => m.ImageGalleryModule),
    //   },
    //   {
    //     path: 'business-profile',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/business-profile/business-profile.module'
    //       ).then((m) => m.BusinessProfileModule),
    //   },
    //   {
    //     path: 'custom-pages',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/custom-page/custom-page.module'
    //       ).then((m) => m.CustomPageModule),
    //   },
    //   {
    //     path: 'testimonials',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/testimonial/testimonial.module'
    //       ).then((m) => m.TestimonialModule),
    //   },
    //   {
    //     path: 'content-sharing-setting',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/content-sharing-setting/content-sharing-setting.module'
    //       ).then((m) => m.ContentSharingSettingModule),
    //   },
    //   {
    //     path: 'other-sections',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/other-section/other-section.module'
    //       ).then((m) => m.OtherSectionModule),
    //   },
    //   {
    //     path: 'republish-website',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/republish-website/republish-website.module'
    //       ).then((m) => m.RepublishWebsiteModule),
    //   },
    //   {
    //     path: 'staff-listing',
    //     loadChildren: () =>
    //       import(
    //         '../../../manage-content-app/src/app/staff-listing/staff-listing.module'
    //       ).then((m) => m.StaffListingModule),
    //   },
    // ],
    canActivate: [AuthGuard],
  },

  {
    path: 'enterprise/manage-content',
    loadChildren: () =>
      import('../../../manage-content-app/src/app/app.module').then(
        (module) => module.AppModule
      ),
  },

  {
    path: 'enterprise/e-commerce',
    children: [
      {
        path: 'business-enquiries',
        loadChildren: () =>
          import(
            '../../../manage-user-app/src/app/enquiry/enquiry.module'
          ).then((m) => m.EnquiryModule),
      },
      {
        path: 'business-calls',
        loadChildren: () =>
          import(
            '../../../manage-content-app/src/app/business-call/business-call.module'
          ).then((m) => m.BusinessCallModule),
      },
      {
        path: 'social',
        loadChildren: () =>
          import(
            '../../../manage-report-app/src/app/social/social.module'
          ).then((m) => m.SocialModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-report',
    // loadChildren: () =>
    //   import('../../../manage-report-app/src/app/sample/sample.module').then(
    //     (module) => module.SampleModule
    //   ),
    children: [
      {
        path: 'social',
        loadChildren: () =>
          import(
            '../../../manage-report-app/src/app/social/social.module'
          ).then((m) => m.SocialModule),
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('../../../manage-report-app/src/app/sale/sale.module').then(
            (m) => m.SaleModule
          ),
      },
      {
        path: 'appointment-logs',
        loadChildren: () =>
          import(
            '../../../manage-report-app/src/app/appointment-log/appointment-log.module'
          ).then((m) => m.AppointmentLogModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'addon-marketplace',
    // loadChildren: () =>
    //   import(
    //     '../../../addon-marketplace-app/src/app/sample/sample.module'
    //   ).then((module) => module.SampleModule),
    children: [
      {
        path: 'marketplace',
        loadChildren: () =>
          import(
            '../../../addon-marketplace-app/src/app/home/home.module'
          ).then((module) => module.HomeModule),
        canActivate: [AuthGuard],
      },

      // {
      //   path: 'installed-addons',
      //   loadChildren: () =>
      //     import(
      //       '../../../addon-marketplace-app/src/app/installed-addon/installed-addon.module'
      //     ).then((m) => m.InstalledAddonModule),
      // },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'setting',
    // loadChildren: () =>
    //   import('../../../setting-app/src/app/sample/sample.module').then(
    //     (module) => module.SampleModule
    //   ),

    children: [
      {
        path: 'my-account',
        loadChildren: () =>
          import(
            '../../../setting-app/src/app/my-account/my-account.module'
          ).then((m) => m.MyAccountModule),
      },
      {
        path: 'my-bank-account',
        loadChildren: () =>
          import(
            '../../../setting-app/src/app/my-bank-account/my-bank-account.module'
          ).then((m) => m.MyBankAccountModule),
      },
      {
        path: 'renewal-history',
        loadChildren: () =>
          import(
            '../../../setting-app/src/app/renewal-history/renewal-history.module'
          ).then((m) => m.RenewalHistoryModule),
      },
      {
        path: 'domain-and-email',
        loadChildren: () =>
          import(
            '../../../setting-app/src/app/domain-and-email/domain-and-email.module'
          ).then((m) => m.DomainAndEmailModule),
      },
      {
        path: 'my-digital-channels',
        loadChildren: () =>
          import(
            '../../../setting-app/src/app/my-digital-channel/my-digital-channel.module'
          ).then((m) => m.MyDigitalChannelModule),
      },
      {
        path: 'logout',
        loadChildren: () =>
          import('../../../setting-app/src/app/logout/logout.module').then(
            (m) => m.LogoutModule
          ),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'enterprise/setting',
    children: [
      {
        path: 'change-password',
        loadChildren: () =>
          import(
            '../../../setting-app/src/app/change-password/change-password.module'
          ).then((m) => m.ChangePasswordModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
