import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'MY REPORTS';
const titleName = 'Boost 360: Business Enquiries';

// var client = 'Boost 360:';
export const NF_MANAGE_REPORT_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.DOC,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Appointment Logs',
          url: '/manage-report/appointment-logs',
          meta: {
            boostTitle: 'Boost 360: AppointmentLogs',
            jioTitle: 'JioOnline: AppointmentLogs'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.HOS,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Appointment Logs',
          url: '/manage-report/appointment-logs',
          meta: {
            boostTitle: 'Boost 360: AppointmentLogs',
            jioTitle: 'JioOnline: AppointmentLogs'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.HOT,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.CAF,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.EDU,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.SPA,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.SAL,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.RTL,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageReport,
    appExpCode: NfAppExpCode.SVC,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Social',
          url: '/manage-report/social',
          meta: {
            boostTitle: 'Boost 360: Social',
            jioTitle: 'JioOnline: Social'
          }
        },
        {
          name: 'Sales',
          url: '/manage-report/sales',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
      ],
    },
  },
];
