import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CanAutoUnsubscribe, NfApiParams, NfApiService } from '@nf/common';
import { NfClientService } from '@nf/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LocalStorageKeys } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { AuthToken } from '../modal/select-store/select-store.model';

@Injectable({
  providedIn: 'root',
})
@CanAutoUnsubscribe
export class MlcService {
  private _isMlcLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _MlcLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private stores: AuthToken[] = [];

  public isMLC: boolean = false;
  public parentId: string = '';

  public isMLCStore: boolean = false;
  public fpId: string = '';
  public fpTag: string = '';
  public appExpCode: string = '';
  public rootAliasUri: string = '';
  public logoUrl: string = '';
  public authToken?: any;
  public activeStores: number = 0;

  //Subscription variable
  public routeSub?: Subscription;
  public appExpSub?: Subscription;
  public mlcClientID: string =
    'AC16E0892F2F45388F439BDE9F6F3FB5C31F0FAA628D40CD9814A79D8841397E';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: NfClientService,
    private apiService: NfApiService,
    private toastr: ToastrService,
    private loginService: LoginService
  ) {
    this.routeSub = this.loginService.isLoggedIn$.subscribe((res) => {
      if (res) {
        const user = JSON.parse(localStorage.getItem(LocalStorageKeys.user)!);
        this.stores = user.authTokens;
      }
    });
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.isMLC = window.location.href.includes('enterprise');
      const storeId = params['storeId'];
      if (
        storeId &&
        this.stores &&
        this.stores.length &&
        this.fpId != storeId
      ) {
        this.setMlcLoader(true);
        this.setMlcLoad(false);
        for (let i = 0; i < this.stores.length; i++) {
          if (this.stores[i].FloatingPointId == storeId) {
            this.isMLCStore = true;
            this.authToken = this.stores[i];
            this.fpId = this.stores[i].FloatingPointId;
            this.fpTag = this.stores[i].FloatingPointTag;
            this.rootAliasUri = this.stores[i].RootAliasUri;
            this.logoUrl = this.stores[i].LogoUrl;
            this._getActiveCount();
            this._getAppExpCode(this.fpId);
            break;
          }
        }
      } else if (this.isMLC) {
        const user = JSON.parse(
          localStorage.getItem(LocalStorageKeys.user) || ''
        );
        if (user) this.parentId = user.loginId;
        this.isMLCStore = false;
        this.fpId = '';
        this.fpTag = '';
        this.appExpCode = '';
      }
    });
  }

  get isMlcLoaded$() {
    return this._isMlcLoaded$.asObservable();
  }

  setMlcLoad(mlcLoaded: boolean) {
    this._isMlcLoaded$.next(mlcLoaded);
  }

  get mlcLoader$() {
    return this._MlcLoader$.asObservable();
  }

  setMlcLoader(isLoading: boolean) {
    this._MlcLoader$.next(isLoading);
  }

  private _getActiveCount() {
    let count = 0;
    const user = JSON.parse(localStorage.getItem(LocalStorageKeys.user)!);
    if (user)
      for (let i = 0; i < user.authTokens.length; i++)
        if (user.authTokens[i].PaymentState == 1) count++;

    this.activeStores = count;
  }

  private _getAppExpCode(fpId: string) {
    this.appExpSub = this.apiService
      .get<any>({
        apiPath: `/discover/v2/floatingPoint/nf-app/${fpId}`,
        params: new NfApiParams().append(
          'clientId',
          this.clientService.defaultClientConfig().clientId
        ),
      })
      .subscribe(
        (res) => {
          if (res && res.AppExperienceCode)
            this.appExpCode = res.AppExperienceCode;
          this.setMlcLoad(true);
          this.setMlcLoader(false);
          // console.log(res);
        },
        (err) => {
          this.setMlcLoader(false);
          localStorage.clear();
          this.router.navigate(['/login']);
          this.toastr.error(
            'Due to some issue we are not able to generate the App Exp Code',
            'Error'
          );
        }
      );
  }
}
