import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageKeys } from '../environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService) {}
  canActivate() {
    const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
    const appExpCode = localStorage.getItem(LocalStorageKeys.app_exp_code);
    const user = localStorage.getItem(LocalStorageKeys.user);
    const token = localStorage.getItem(LocalStorageKeys.token);

    if (authToken && appExpCode && user && token) {
      return true;
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
      return false;
    }
  }
}
