import { ApiRequestGenerator } from 'projects/common-lib/src/lib/Utils/Api-requestgenerator';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  CanAutoUnsubscribe,
  NfApiConfig,
  NfApiHeaders,
  NfApiParams,
  NfApiService,
} from '@nf/common';
import { NfClientService } from '@nf/core';
import {
  environment,
  LocalStorageKeys,
} from 'projects/main-app/src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
@CanAutoUnsubscribe
export class LoginService {
  private _isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _showDownloadBtn$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  private _openBoostApp$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _changeHeaderLogo$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public apiConfig: NfApiConfig = environment.apiConfig;

  //Subscription variables
  public tokenSub?: Subscription;
  public setTokenSub?: Subscription;
  public appExpSub?: Subscription;

  constructor(
    private apiService: NfApiService,
    private clientService: NfClientService,
    private toastr: ToastrService,
    private router: Router
  ) {
    //handling login through local storage
    if (this.getLocalStorage(LocalStorageKeys.token)) {
      this.setLogin(true);
    } else {
      this.setLogin(false);
    }
  }

  get isLoggedIn$() {
    return this._isLoggedIn$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get showDownloadBtn$() {
    return this._showDownloadBtn$.asObservable();
  }

  get openBoostApp$() {
    return this._openBoostApp$.asObservable();
  }

  get changeHeaderLogo$() {
    return this._changeHeaderLogo$.asObservable();
  }

  setLogin(isLoggedIn: boolean) {
    this._isLoggedIn$.next(isLoggedIn);
  }

  setLoading(isLoading: boolean) {
    this._isLoading$.next(isLoading);
  }

  setShowDownloadBtn(show: boolean) {
    this._showDownloadBtn$.next(show);
  }

  setOpenBoostApp(open: boolean) {
    this._openBoostApp$.next(open);
  }

  setChangedHeaderLogo(change: boolean) {
    this._changeHeaderLogo$.next(change);
  }

  setLocalStorage(key: string, data: string) {
    localStorage.setItem(key, data);
  }

  getLocalStorage(key: string) {
    return localStorage.getItem(key);
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  getAccessToken(authToken: any) {
    // this.loginService.setLoading(true);
    const data = {
      fpId: authToken.FloatingPointId,
      authToken: authToken.AuthenticationToken,
      clientId: this.clientService.currentClientConfig().clientId,
    };
    this.tokenSub = this.apiService
      .post<any>(data, {
        apiPath: '/discover/v1/floatingpoint/AccessToken/Create',
      })
      .subscribe(
        (data) => {
          if (data && data.Result && data.Result.Token) {
            this.setLocalStorage(this.apiConfig.tokenKey!, data.Result.Token);
            this.setLocalStorage(
              this.apiConfig.refreshToken!,
              data.Result.RefreshToken
            );
            this.setMainToken(authToken);
            // this.router.navigateByUrl('/dashboard');
            // this.loginService.setLogin(true);
          } else {
            this.toastr.error('Error', 'Please check details');
            this.setLoading(false);
          }
        },
        (error: any) => {
          this.toastr.error('Error', 'Please check details');
          this.setLoading(false);
        }
      );
  }

  setMainToken(authToken: any) {
    let floatingPointId = authToken.FloatingPointId;
    let boostUser: any = localStorage.getItem(LocalStorageKeys.user);
    boostUser = JSON.parse(boostUser);
    let boostUserLoginId = boostUser.loginId;
    let customerClientId = this.clientService.currentClientConfig().clientId;

    this.setTokenSub = this.apiService
      .get<any>({
        config: { baseUrl: 'https://tokenserver.withfloats.com' },
        apiPath: '/api/generate-token',
        headers: new NfApiHeaders()
          .append('x-fpid', floatingPointId)
          .append('x-clientid', customerClientId)
          .append('x-loginid', boostUserLoginId)
          .append('Access-Control-Allow-Origin', '*'),
      })
      .subscribe(
        (res) => {
          this.setAppExpCode(
            authToken.FloatingPointId,
            this.clientService.currentClientConfig().clientId
          );
          this.setLocalStorage(this.apiConfig.mainToken!, res.token);
          // this.setLoading(false);
        },
        (err) => {
          this.toastr.error(
            'Due to some issue we are not able to generate login token, please try after sometime',
            'Login Error'
          );
          this.setLoading(false);
        }
      );
  }

  setAppExpCode(fpID: string, clientID: string) {
    // this.setLoading(true);
    this.appExpSub = this.apiService
      .get<any>({
        apiPath: `/discover/v2/floatingPoint/nf-app/${fpID}`,
        params: new NfApiParams().append('clientId', clientID),
      })
      .subscribe(
        (res) => {
          this.setLocalStorage(
            LocalStorageKeys.app_exp_code,
            res.AppExperienceCode
          );
          this.router.navigateByUrl('/dashboard');
          this.setLogin(true);
          this.setLoading(false);
        },
        (err) => {
          this.toastr.error(
            'Due to some issue we are not able to generate login token, please try after sometime',
            'Login Error'
          );
          this.setLoading(false);
          this.setLogin(false);
        }
      );
  }
}
