import { LocalStorageKeys } from './../../../../../main-app/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  NfApiParams,
  NfApiService,
  NfApiHeaders,
  CanAutoUnsubscribe,
} from '@nf/common';
import { ConnectSocialComponent } from '../connect-social/connect-social.component';
import { NfClientService } from '@nf/core';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { DrScoreMetric } from 'projects/common-lib/src/lib/Utils/dr-score-metric';
import { DRScoreUpdate } from 'projects/common-lib/src/lib/Utils/dr-score-update';
import { environment } from 'projects/manage-content-app/src/environments/environment';
import { Subscription } from 'rxjs';
import { MlcService } from 'projects/main-app/src/app/services/mlc.service';

@Component({
  selector: 'mc-add-update',
  templateUrl: './add-update.component.html',
  styleUrls: ['./add-update.component.scss'],
})
@CanAutoUnsubscribe
export class AddUpdateComponent implements OnInit {
  public isRich: boolean = false;
  public showLoader: boolean = false;
  public message: string = '';
  private quillTextMessage: string = '';

  //image upload
  public primaryFile: any;
  public primaryFileBase64: any;
  public primaryImageUrl: string = '';
  public fileData: any = {};

  //social api data
  public fpId: string = '';
  public fpTag: string = '';
  public isSubscriber: boolean = true;
  public isFacebook: boolean = false;
  public isFacebookConnected: boolean = false;
  public isTwitter: boolean = false;
  public isTwitterConnected: boolean = false;

  //quill editor module
  quillModules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      ['clean'],
      [
        {
          font: [],
        },
      ],
      [{ color: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  };

  //subscription variables
  public postUpdateSub?: Subscription;
  public uploadImageSub?: Subscription;
  public socialSub?: Subscription;
  public dialogSub?: Subscription;
  public clientId: string = '';
  constructor(
    private toastr: ToastrService,
    private clientService: NfClientService,
    public dialog: MatDialog,
    private apiService: NfApiService,
    private mlcService: MlcService,
    public dialogRef: MatDialogRef<AddUpdateComponent>
  ) {
    if (this.mlcService.isMLCStore) {
      this.fpId = this.mlcService.fpId;
      this.fpTag = this.mlcService.fpTag;
    } else {
      const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
      if (authToken && authToken.length) {
        const parsedData = JSON.parse(authToken);
        if (this.mlcService.isMLC) this.fpId = this.mlcService.parentId;
        else this.fpId = parsedData.FloatingPointId;
        this.fpTag = parsedData.FloatingPointTag;
      }
    }
    if (this.mlcService.isMLC) {
      this.clientId = this.mlcService.mlcClientID;
    } else {
      this.clientId = this.clientService.currentClientConfig().clientId;
    }
    this.checkSocial();
  }

  ngOnInit(): void {}

  postUpdate() {
    if (!this.message) {
      this.toastr.error('Message cannot be empty', 'Write Message', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }
    this.showLoader = true;
    const socialParams: string[] = [];
    if (this.isTwitter) socialParams.push('TWITTER');
    if (this.isFacebook) socialParams.push('FACEBOOK_PAGE');

    if (socialParams.length > 0) {
      DRScoreUpdate.markEventTrue(
        this.apiService,
        this.clientService.currentClientConfig().clientId,
        this.fpTag,
        DrScoreMetric.SHARE_MULTICHANNEL_POST
      );
    }

    this.postUpdateSub = this.apiService
      .put<any>(
        {
          merchantID: this.fpId,
          clientId: this.clientId,
          sendToSubscribers: this.isSubscriber,
          socialParameters: socialParams.join('.'),
          message: this.message,
          isPictureMessage: this.primaryFileBase64 ? true : false,
          IsHtmlString: this.isRich,
        },
        {
          apiPath: '/discover/v2/FloatingPoint/createBizMessage',
        }
      )
      .subscribe(
        (res) => {
          if (this.primaryFileBase64) {
            this.uploadImage(res, socialParams.join('.'));
          } else {
            this.showLoader = false;

            if (this.isRich) {
              this.dialogRef.close(this.quillTextMessage);
            } else {
              this.dialogRef.close(this.message);
            }
            this.toastr.success(
              'The update was posted successfully',
              'Posted',
              {
                positionClass: 'toast-bottom-right',
              }
            );
          }
        },
        (err) => {
          this.showLoader = false;
          this.toastr.error(
            'There is some issue while posting the update',
            'Error',
            {
              positionClass: 'toast-bottom-right',
            }
          );
        }
      );
  }

  uploadImage(updateId: string, socialParams: string) {
    this.uploadImageSub = this.apiService
      .put<any>(
        this.primaryFile, //sending image file
        {
          apiPath: '/Discover/v1/FloatingPoint/createBizImage',
          params: new NfApiParams()
            .append(
              'clientId',
              this.clientService.defaultClientConfig().clientId
            )
            .append('requestType', 'sequential')
            .append('requestId', uuidv4())
            .append('totalChunks', 1)
            .append('currentChunkNumber', 1)
            .append('bizMessageId', updateId)
            .append('sendToSubscribers', this.isSubscriber)
            .append('socialParmeters', socialParams)
            .append('fileName', this.primaryFile.name),
        }
      )
      .subscribe(
        (res) => {
          this.showLoader = false;
          this.dialogRef.close(this.message);
          this.toastr.success('The update was posted successfully', 'Posted', {
            positionClass: 'toast-bottom-right',
          });
        },
        (err) => {
          this.showLoader = false;
          this.toastr.error(
            'There is some issue while posting the update',
            'Error',
            {
              positionClass: 'toast-bottom-right',
            }
          );
        }
      );
  }

  richEditor(event: any) {
    this.message = event.html;
    this.quillTextMessage = event.text;
  }

  changeEditor() {
    this.isRich = !this.isRich;
    this.message = '';
    this.quillTextMessage = '';
  }

  checkSocial() {
    this.socialSub = this.apiService
      .get<any>({
        config: { baseUrl: 'https://nfx.withfloats.com' },
        apiPath: `/dataexchange/v2/channelstatus`,
        params: new NfApiParams().append('nowfloats_id', this.fpId),
        headers: new NfApiHeaders()
          .append('key', environment.deactivateKey)
          .append('pwd', environment.deactivatePwd),
      })
      .subscribe((res) => {
        if (
          res.channels &&
          res.channels.twitter &&
          res.channels.twitter.status == 'CONNECTED'
        ) {
          this.isTwitter = true;
          this.isTwitterConnected = true;
        }
        if (
          res.channels &&
          res.channels.facebookpage &&
          res.channels.facebookpage.status == 'CONNECTED'
        ) {
          this.isFacebook = true;
          this.isFacebookConnected = true;
        }
      });
  }

  changeCheckbox(event: any, type: string) {
    switch (type) {
      case 'twitter':
        if (this.isTwitterConnected) this.isTwitter = !this.isTwitter;
        else this.onOpenConnectModal();
        break;
      case 'facebook':
        if (this.isFacebookConnected) this.isFacebook = !this.isFacebook;
        else this.onOpenConnectModal();

        break;
    }
    event.preventDefault();
  }

  onOpenConnectModal() {
    const dialogRef = this.dialog.open(ConnectSocialComponent, {
      width: '240px',
      backdropClass: 'popupBackdropClass',
      panelClass: 'connect-social',
      disableClose: true,
    });

    this.dialogSub = dialogRef.afterClosed().subscribe((res) => {});
  }

  closeModal() {
    this.dialogRef.close();
  }

  changeFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  uploadPrimaryFile(event: any) {
    if (event.target.value) {
      const file = event.target.files[0];
      this.changeFile(file).then((base64: any) => {
        this.primaryFileBase64 = base64;
        this.primaryFile = file;
      });
    } else alert('Nothing');
  }

  removeImage() {
    this.primaryFileBase64 = null;
    this.primaryFile = null;
  }

  //for demo
  // checked = false;
  // onCheckedItem(event: any) {
  //   this.checked = false;
  //   // event.preventDefault();
  // }
}
