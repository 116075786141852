import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NfApiService,
  NfApiParams,
  NfApiTokenType,
  NfApiTokenStorageType,
  NfApiHeaders,
  CanAutoUnsubscribe,
} from '@nf/common';
import { NfClientService } from '@nf/core';
import { ToastrService } from 'ngx-toastr';
import { DrScoreMetric } from 'projects/common-lib/src/lib/Utils/dr-score-metric';
import { DRScoreUpdate } from 'projects/common-lib/src/lib/Utils/dr-score-update';
import { MlcService } from 'projects/main-app/src/app/services/mlc.service';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'setting-book-new-domain',
  templateUrl: './book-new-domain.component.html',
  styleUrls: ['./book-new-domain.component.scss'],
})
@CanAutoUnsubscribe
export class BookNewDomainComponent implements OnInit {
  public domain: string = '.COM';
  public isAvailable: boolean = false;
  public isChecking: boolean = false;
  public isLoading: boolean = false;
  private fpTag: string = '';
  private fpId: string = '';
  public floatingPointDetails: any;
  public domainPrice: string = 'INR 780';
  public domainName: string = '';

  //subscription variables
  public checkDomainSub?: Subscription;
  public fpDetailsSub?: Subscription;
  public activateDomainSub?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<BookNewDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clientService: NfClientService,
    private apiService: NfApiService,
    private toastr: ToastrService,
    private mlcService: MlcService
  ) {
    if (data) {
      this.domainName = data.domainName ? data.domainName : '';
      this.domain = data.domainType ? data.domainType : '.COM';
      if (this.domainName.length) {
        this.checkDomain(this.domainName, true);
      }
    }
    if (this.mlcService.isMLCStore) {
      this.fpId = this.mlcService.fpId;
      this.fpTag = this.mlcService.fpTag;
    } else {
      const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
      if (authToken && authToken.length) {
        const parsedData = JSON.parse(authToken);
        this.fpId = parsedData.FloatingPointId;
        this.fpTag = parsedData.FloatingPointTag;
      }
    }
  }

  ngOnInit(): void {
    this.getFloatingPointDetailsById();
    this.getDomainPrice();
  }

  closeModal() {
    this.dialogRef.close();
  }

  checkDomain(event: any, isInternal?: boolean) {
    const domainName = isInternal ? event : event.target.value;
    this.isChecking = true;
    this.isLoading = true;
    this.checkDomainSub = this.apiService
      .get<any>({
        config: {
          baseUrl: 'http://plugin.withfloats.com',
        },
        apiPath: `/DomainService/v1/checkAvailability/${domainName}`,
        // headers: new NfApiHeaders().append('Authorization', 'Bearer '),
        params: new NfApiParams()
          .append('clientId', this.clientService.defaultClientConfig().clientId)
          .append('domainType', this.domain),
      })
      .subscribe(
        (isAvailable: any) => {
          this.isLoading = false;
          this.isAvailable = isAvailable;
        },
        (error: any) => {
          this.isChecking = false;
        }
      );
  }

  getFloatingPointDetailsById() {
    const body = {
      clientId: this.clientService.defaultClientConfig().clientId,
      floatingPointId: this.fpId,
    };
    this.fpDetailsSub = this.apiService
      .post<any>(body, {
        apiPath: '/internal/v1/GetFloatingPointDetailsById',
      })
      .subscribe(
        (res: any) => {
          this.floatingPointDetails = res;
          this.domainPrice = this.getDomainPrice();
        },
        (error) => {
          this.toastr.error(
            'Somthing went wrong while getting Floating Point Details By Id',
            'Error'
          );
        }
      );
  }

  domainChange() {
    this.domainPrice = this.getDomainPrice();
  }

  getDomainPrice() {
    if (
      this.floatingPointDetails.ApplicationId ==
      '7AB16D01489A45EC8F7C3ABDFE0DD95FA676C5F18B324FC48387BCA92F722DE9'
    ) {
      if (this.domain == '.NET') return 'USD 865';
      if (this.domain == '.COM') return 'USD 680';
      if (this.domain == '.CO.IN') return 'USD 375';
      if (this.domain == '.IN') return 'USD 490';
      else return 'USD 500';
    } else {
      if (this.domain == '.NET') return 'INR 965';
      if (this.domain == '.COM') return 'INR 780';
      if (this.domain == '.CO.IN') return 'INR 475';
      if (this.domain == '.IN') return 'INR 590';
      else return 'INR 600';
    }
  }

  activateDomain() {
    const domainData = {
      clientId: this.clientService.defaultClientConfig().clientId,
      domainName: this.domainName,
      domainType: this.domain,
      existingFPTag: this.fpTag,
      domainChannelType: 1,
      DomainRegService: 0,
      validityInYears: 1,
      DomainOrderType: 0,
    };
    this.activateDomainSub = this.apiService
      .put<any>(domainData, {
        config: {
          baseUrl: 'http://plugin.withfloats.com',
        },
        apiPath: `/DomainService/v2/DomainWithWebsite/create`,
      })
      .subscribe(
        (isAvailable: any) => {
          DRScoreUpdate.markEventTrue(
            this.apiService,
            this.clientService.currentClientConfig().clientId,
            this.fpTag,
            DrScoreMetric.ADD_CUSTOM_DOMAIN_NAME_AND_SSL
          );
          this.closeModal();
        },
        (error: any) => {
          this.closeModal();
        }
      );
  }
}
