<nf-loader
  [loaderText]="'Processing...'"
  *ngIf="mlcService.mlcLoader$ | async"
></nf-loader>

<div *ngIf="!(mlcService.mlcLoader$ | async)" class="relative main-container">
  <nav
    class="navbar navbar-default navbar-fixed-top header-fixed primary-font"
    *ngIf="(loginService.isLoggedIn$ | async) && !isMLC"
  >
    <main-jio-header jio-online></main-jio-header>
    <div class="container nav-container">
      <div id="logoImg" class="navbar-header">
        <div class="fp-logo">
          <img *ngIf="logoUrl" [src]="logoUrl" alt="logo" />

          <mat-icon *ngIf="!logoUrl" class="account-logo-icon"
            >account_circle</mat-icon
          >
        </div>

        <div class="head-normal-view">
          <div class="brand-heading">
            <a class="nav-brand">
              <span class="brand-text">{{ authToken.Name }}</span>
              <span id="nav-rootaliasUri">
                <mat-icon>open_in_new</mat-icon>
                <a
                  class="cursor-pointer text-color underline rootAliasUrl"
                  matTooltip="Click to open {{ authToken.RootAliasUri }}"
                  matTooltipPosition="right"
                  matTooltipClass="input-tooltip"
                  [href]="authToken.RootAliasUri"
                  target="_blank"
                  >{{ authToken.RootAliasUri }}</a
                >
              </span>
              <span class="mlc-store" *ngIf="mlcService.isMLCStore">
                <div class="store-icon">
                  <svg
                    version="1.1"
                    id="store-selector-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="14px"
                    height="14px"
                    viewBox="0 0 428.103 428.102"
                    style="enable-background: new 0 0 428.103 428.102"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        fill="#aaa"
                        d="M409.413,99.792h1.832c5.295,0,9.948-2.138,13.105-6.021c3.272-4.024,4.483-9.535,3.32-15.116l-8.756-42.063 c-2.184-10.482-11.538-18.693-21.297-18.693l-244.754,0.002H30.489c-9.759,0-19.114,8.211-21.297,18.693l-8.76,42.061 c-1.162,5.582,0.048,11.092,3.32,15.116c3.158,3.883,7.813,6.022,13.109,6.022h1.833c-4.647,25.018-9.303,50.033-13.958,75.051 l-0.372,2c-4.421,23.089,6.292,44.422,27.446,55.37v151.99c0,14.336,11.663,26,26,26h312.482c14.337,0,26-11.664,26-26V232.256 c21.192-10.938,31.906-32.29,27.454-55.381L409.413,99.792z M138.18,178.417c1.955-26.208,3.914-52.417,5.873-78.625h50.24 c-0.349,26.033-0.701,52.062-1.057,78.071c-0.289,16.09-15.25,26.083-29.012,26.116c-7.609,0-15.11-3.19-20.064-8.534 C141.094,192.136,137.567,186.499,138.18,178.417z M64.364,203.979l-0.307-0.006l-0.383,0.007c-7.362,0-14.396-3.131-18.817-8.376 c-2.747-3.259-5.738-8.787-4.386-16.662l12.966-79.148h50.598l-7.921,77.512C94.452,192.165,80.465,203.882,64.364,203.979z M198.294,304.532c0,5.523-4.478,10-10,10h-86c-5.522,0-10-4.477-10-10v-29c0-5.523,4.478-10,10-10h86c5.522,0,10,4.477,10,10 V304.532z M235.356,177.887l-1.091-78.095h50.24c1.931,25.716,3.865,51.433,5.799,77.148l0.111,1.485 c0.613,8.076-2.912,13.714-5.979,17.023c-4.951,5.341-12.447,8.53-20.12,8.53C250.622,203.947,235.656,193.951,235.356,177.887z M335.81,361.241c0,5.521-4.478,10-10,10h-53.496c-5.521,0-9.999-4.479-9.999-10v-75.996c0-5.523,4.478-10,9.999-10h53.496 c5.522,0,10,4.477,10,10V361.241z M383.248,195.646c-4.398,5.218-11.395,8.334-18.715,8.334l-0.19-0.001 c-16.201-0.115-30.197-11.833-31.857-26.621c-2.654-25.854-5.309-51.71-7.96-77.566h50.146l12.973,79.196 C388.994,186.841,385.999,192.381,383.248,195.646z"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div class="city">{{ city }}</div>
                <div *ngIf="mlcService.activeStores > 1" class="select-store">
                  <span
                    class="select-store-text"
                    *ngIf="mlcService.activeStores <= 2"
                  >
                    (Select from other 1 store)
                  </span>
                  <span
                    (click)="openAllStore()"
                    class="select-store-text"
                    *ngIf="mlcService.activeStores > 2"
                  >
                    (Select from other {{ mlcService.activeStores - 1 }} store)
                  </span>
                </div>
              </span>
            </a>
          </div>
        </div>
        <div class="head-res-view">
          <div class="brand-heading">
            <a class="nav-brand">
              <span class="brand-text" *ngIf="authToken && authToken.Name">
                {{ authToken.Name | truncate: [15, '...'] }}
              </span>
              <span id="nav-rootaliasUri">
                <mat-icon>open_in_new</mat-icon>
                <a
                  class="cursor-pointer text-color underline rootAliasUrl"
                  matTooltip="Click to open {{ authToken.RootAliasUri }}"
                  matTooltipPosition="right"
                  matTooltipClass="input-tooltip"
                  [href]="authToken.RootAliasUri"
                  target="_blank"
                  *ngIf="authToken && authToken.RootAliasUri"
                >
                  {{ authToken.RootAliasUri | truncate: [26, '...'] }}
                </a>
              </span>
            </a>
          </div>
        </div>
      </div>

      <ul class="nav navbar-nav navbar-right navbar-menus">
        <li id="mobile-site-health">
          <div>90%</div>
          <div id="mobile-site-meter-container">
            <div id="mobile-site-meter-percentage"></div>
          </div>
        </li>
        <li
          class="
            notify-icon-item
            dropdown dropdown-extended dropdown-notification dropdown-dark
          "
        >
          <a
            class="dropdown-toggle cursor-pointer"
            (click)="onClickNotificationIcon()"
            matBadge="{{ notificationCount }}"
            matBadgePosition="after"
            matBadgeColor="warn"
            matTooltip="Alerts"
            matTooltipPosition="left"
            matTooltipClass="input-tooltip-left"
            *ngIf="isNotificationCount"
          >
            <img src="assets/images/notification.svg" />
          </a>

          <a
            class="dropdown-toggle cursor-pointer"
            (click)="onClickNotificationIcon()"
            matTooltip="Alerts"
            matTooltipPosition="left"
            matTooltipClass="input-tooltip-left"
            *ngIf="!isNotificationCount"
          >
            <img src="assets/images/notification.svg" />
          </a>
          <div *ngIf="showNotificationList" class="notification-container">
            <ul>
              <li class="external">
                <span>{{ notificationCount }} Pending Notification</span>
              </li>
              <ul class="header-notification-list">
                <li *ngFor="let notif of notficationsList">
                  <a (click)="onClickViewNotifications()">
                    <p class="not-description">
                      {{ notif?.message }}
                    </p>
                    <p
                      style="color: #c1a2a2; display: flex; align-items: center"
                    >
                      <mat-icon style="font-size: 14px; height: fit-content"
                        >access_time</mat-icon
                      >
                      <span>{{ notif.createdOn }}</span>
                    </p>
                  </a>
                </li>
              </ul>
              <li class="external" style="text-align: center">
                <span (click)="onClickViewNotifications()">View All</span>
              </li>
            </ul>
          </div>
        </li>
        <li class="hamburger-menu" (click)="onHamburgerClick()">
          <div id="hamburger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
            >
              <rect width="19" height="3" rx="1.5" fill="#C4C4C4"></rect>
              <rect y="7" width="19" height="3" rx="1.5" fill="#C4C4C4"></rect>
              <rect y="14" width="19" height="3" rx="1.5" fill="#C4C4C4"></rect>
            </svg>
          </div>
        </li>

        <li class="display-none">
          <a
            href="javascript:void(0)"
            (click)="openNeedHelpModal()"
            matTooltip="Help & Support"
            matTooltipPosition="left"
            matTooltipClass="input-tooltip-left"
          >
            <img src="assets/images/support.svg" />
          </a>
        </li>
        <li jio-online class="display-none">
          <a
            href="javascript:void(0)"
            (click)="onWatchVideoPopUp()"
            matTooltip="Tutorials"
            matTooltipPosition="left"
            matTooltipClass="input-tooltip-left"
          >
            <img src="assets/images/film.svg" />
          </a>
        </li>
        <li class="post-update-item container-relative display-none">
          <button
            class="post-update-link"
            [ngStyle]="{ background: isOptionList ? '#747474' : '' }"
            (click)="onListIconClick()"
          >
            <mat-icon *ngIf="isOptionList" class="add-circle-icon"
              >close</mat-icon
            >
            <mat-icon *ngIf="!isOptionList" class="add-circle-icon"
              >add</mat-icon
            >
          </button>
          <div *ngIf="isOptionList">
            <ul class="options-list">
              <li (click)="orderListRouting(1)">
                <svg
                  class="icon1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM5.68969 14.9884C5.652 14.9961 5.6143 15 5.5766 15C5.42505 15 5.27811 14.94 5.16886 14.8315C5.03269 14.6954 4.97345 14.5 5.01115 14.3107L5.55044 11.6159C5.57276 11.5044 5.62738 11.4013 5.70816 11.3213L11.6374 5.39176C12.1598 4.86941 13.0091 4.86941 13.5307 5.39176L14.6085 6.4695C15.1301 6.99106 15.1309 7.84112 14.6085 8.36344L8.67931 14.2922C8.59931 14.3731 8.497 14.4277 8.38463 14.4499L5.68969 14.9884Z"
                    fill="white"
                  />
                </svg>
                Post an Update
              </li>
              <li (click)="orderListRouting(2)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  class="icon icon2"
                >
                  <path
                    d="M18.5334 1.79996L18.5327 8.6263C18.5327 8.96435 18.3985 9.28924 18.1587 9.5283L10.0366 17.6504L10.1613 17.775C10.734 18.3477 11.6623 18.3477 12.2351 17.775L19.5696 10.442C19.8453 10.167 20 9.79374 20 9.40507V3.26657C20 2.45627 19.3437 1.79996 18.5334 1.79996Z"
                    fill="#A7A7A7"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.45944 0.333344H15.6002C16.4104 0.333344 17.0668 0.989654 17.0668 1.79995V7.9399C17.0668 8.32929 16.9121 8.70257 16.6371 8.97829L8.644 16.9698C8.07133 17.5426 7.14294 17.5426 6.57022 16.9698L0.429534 10.8292C-0.143178 10.2557 -0.143178 9.32734 0.429534 8.75462L8.42256 0.763061C8.69756 0.488071 9.07083 0.333344 9.45944 0.333344ZM11.9336 4.36652C11.9336 4.9737 12.4264 5.46648 13.0336 5.46648C13.6408 5.46648 14.1336 4.9737 14.1336 4.36652C14.1336 3.75935 13.6408 3.26656 13.0336 3.26656C12.4264 3.26656 11.9336 3.75935 11.9336 4.36652ZM10.0703 7.61845H9.46094C9.4145 7.38633 9.33905 7.21222 9.24622 7.09617H10.0703C10.3894 7.09617 10.6506 6.83506 10.6506 6.51589C10.6506 6.19672 10.3894 5.93561 10.0703 5.93561H6.14167C5.8225 5.93561 5.56133 6.19672 5.56133 6.51589C5.56133 6.83506 5.8225 7.09617 6.14167 7.09617H7.26161C7.65622 7.09617 8.00439 7.27028 8.20172 7.61845H6.13583C5.81667 7.61845 5.55555 7.87961 5.55555 8.19878C5.55555 8.51789 5.81667 8.77906 6.13583 8.77906H8.20172C8.15528 8.84867 8.10305 8.9125 8.045 8.97633C7.83611 9.18528 7.55178 9.30134 7.26161 9.30134H6.14744C5.83411 9.2955 5.57294 9.54506 5.56717 9.86422C5.56717 10.0209 5.631 10.1776 5.74128 10.2936L8.54989 12.9456C8.65433 13.0442 8.79939 13.0964 8.95028 13.0964C9.26944 13.0964 9.53055 12.8353 9.53055 12.5162C9.53055 12.3537 9.46672 12.2028 9.35067 12.0926L7.58661 10.4213C8.07405 10.3574 8.52667 10.1369 8.88067 9.78878C9.15339 9.50439 9.3565 9.15622 9.46672 8.77906H10.0703C10.3894 8.77906 10.6506 8.51789 10.6506 8.19878C10.6506 7.87961 10.3894 7.61845 10.0703 7.61845Z"
                    fill="#A7A7A7"
                  />
                </svg>
                Add product to catalogue
              </li>
              <li (click)="orderListRouting(3)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  class="icon icon3"
                >
                  <path
                    d="M15.0002 11.9678V4.49521H16.1424C16.9868 4.49521 17.6667 5.18721 17.6667 6.03265V16.1357C17.6667 16.9822 16.9868 17.6667 16.1424 17.6667H6.06126C5.2173 17.6667 4.52417 16.9822 4.52417 16.1357V15.0321H11.9426C13.6192 15.0325 15.0002 13.6485 15.0002 11.9678Z"
                    fill="#A7A7A7"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.9427 0.333344H1.86116C1.01757 0.333344 0.333496 1.01817 0.333496 1.86474V11.9678C0.333496 12.8147 1.01757 13.5048 1.86116 13.5048H11.9427C12.7863 13.5048 13.476 12.8147 13.476 11.9678V1.86437C13.476 1.01854 12.7867 0.333344 11.9427 0.333344ZM2.49092 11.3672C2.31444 11.3672 2.15266 11.275 2.06856 11.1193C1.98485 10.9643 1.99276 10.7784 2.0893 10.6298L3.68409 8.18382C3.79232 8.01754 3.9624 7.90187 4.15661 7.86333C4.35006 7.82444 4.55106 7.86483 4.71472 7.97634L6.30575 9.05915C6.63647 9.28512 7.08708 9.20389 7.31975 8.87623L9.04765 6.44344C9.21058 6.214 9.48965 6.09686 9.76721 6.14224C10.0447 6.18795 10.2718 6.38711 10.3532 6.65658L11.589 10.753C11.6327 10.8993 11.6052 11.055 11.5147 11.1775C11.4242 11.2992 11.2813 11.3672 11.1293 11.3672H2.49092Z"
                    fill="#A7A7A7"
                  />
                </svg>
                Upload to image gallery
              </li>
              <li (click)="orderListRouting(4)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  class="icon icon4"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.0901 0.599976H2.9101C1.63582 0.599976 0.600098 1.63314 0.600098 2.90429V14.4957C0.600098 15.7668 1.63582 16.8 2.9101 16.8H15.0901C16.3644 16.8 17.4001 15.7668 17.4001 14.4957V2.90429C17.4001 1.63314 16.3644 0.599976 15.0901 0.599976ZM3.1201 4.02152C3.1201 3.67462 3.40234 3.39308 3.7501 3.39308H8.3701C8.71786 3.39308 9.0001 3.67462 9.0001 4.02152V4.85945C9.0001 5.20636 8.71786 5.4879 8.3701 5.4879C8.02234 5.4879 7.7401 5.20636 7.7401 4.85945V4.64997H6.6901V8.0017C7.03786 8.0017 7.3201 8.28324 7.3201 8.63014C7.3201 8.97705 7.03786 9.25859 6.6901 9.25859H5.4301C5.08234 9.25859 4.8001 8.97705 4.8001 8.63014C4.8001 8.28324 5.08234 8.0017 5.4301 8.0017V4.64997H4.3801V4.85945C4.3801 5.20636 4.09786 5.4879 3.7501 5.4879C3.40234 5.4879 3.1201 5.20636 3.1201 4.85945V4.02152ZM3.7501 14.2862H14.2501C14.5979 14.2862 14.8801 14.0046 14.8801 13.6577C14.8801 13.3108 14.5979 13.0293 14.2501 13.0293H3.7501C3.40234 13.0293 3.1201 13.3108 3.1201 13.6577C3.1201 14.0046 3.40234 14.2862 3.7501 14.2862ZM14.2501 11.7724H3.7501C3.40234 11.7724 3.1201 11.4908 3.1201 11.1439C3.1201 10.797 3.40234 10.5155 3.7501 10.5155H14.2501C14.5979 10.5155 14.8801 10.797 14.8801 11.1439C14.8801 11.4908 14.5979 11.7724 14.2501 11.7724ZM11.3101 9.25859H14.2501C14.5979 9.25859 14.8801 8.97705 14.8801 8.63015C14.8801 8.28324 14.5979 8.0017 14.2501 8.0017H11.3101C10.9623 8.0017 10.6801 8.28324 10.6801 8.63015C10.6801 8.97705 10.9623 9.25859 11.3101 9.25859ZM14.2501 6.95428H11.3101C10.9623 6.95428 10.6801 6.67274 10.6801 6.32583C10.6801 5.97893 10.9623 5.69739 11.3101 5.69739H14.2501C14.5979 5.69739 14.8801 5.97893 14.8801 6.32583C14.8801 6.67274 14.5979 6.95428 14.2501 6.95428ZM11.3101 4.64997H14.2501C14.5979 4.64997 14.8801 4.36843 14.8801 4.02152C14.8801 3.67462 14.5979 3.39308 14.2501 3.39308H11.3101C10.9623 3.39308 10.6801 3.67462 10.6801 4.02152C10.6801 4.36843 10.9623 4.64997 11.3101 4.64997Z"
                    fill="#A7A7A7"
                  />
                </svg>

                Create a custom page
              </li>
              <li (click)="orderListRouting(5)" *ngIf="!isTestimonial">
                <svg
                  class="icon icon5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.04029 0.200012H15.8858C16.698 0.200012 17.3587 0.860698 17.3587 1.67294V11.4924C17.3587 12.3047 16.698 12.9654 15.8858 12.9654H7.2188L4.41681 15.7674C4.27508 15.9091 4.06275 15.9494 3.88171 15.8738C3.69805 15.798 3.57868 15.6187 3.57868 15.4202V12.9654H2.07302C1.26082 12.9654 0.600098 12.3047 0.600098 11.4924V1.67294C0.600098 0.860732 1.22809 0.200012 2.04029 0.200012ZM10.1455 9.22259L10.1863 9.1886L10.1884 9.18685C11.8255 7.81988 12.9072 6.91671 12.9072 5.51681C12.9072 4.20692 11.9368 3.18085 10.6978 3.18085C9.86113 3.18085 9.31648 3.62725 8.97941 4.07986C8.64234 3.62725 8.09768 3.18085 7.26098 3.18085C6.02204 3.18085 5.05161 4.20689 5.05161 5.51681C5.05161 6.91669 6.13328 7.81985 7.77038 9.18678L7.77256 9.1886L7.81327 9.22259C8.08045 9.44563 8.36355 9.68196 8.6601 9.9361C8.84316 10.0942 9.11644 10.0936 9.29871 9.9361C9.59526 9.68196 9.87836 9.44563 10.1455 9.22259Z"
                    fill="#A7A7A7"
                  />
                </svg>
                Add a testimonial
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="isOptionList" class="more-options-list"></div>
    <div class="nav-menu">
      <ul class="menu-container">
        <li routerLinkActive="active">
          <a (click)="onSetTitle(0, ''); routeChange('/dashboard')">
            Dashboard
          </a>
        </li>

        <li
          *ngIf="manageUserMenu"
          class="has-sub-menu"
          routerLinkActive="active"
        >
          <a>
            Manage&nbsp;<span>{{ manageUserMenu.menu.name }}</span>
          </a>
          <ul class="sub-menu">
            <li
              routerLinkActive="sub-active"
              *ngFor="let item of manageUserMenu.menu.items"
            >
              <a
                *ngIf="item.name !== 'Calls'"
                (click)="onSetTitle(1, ''); routeChange(item.url)"
              >
                {{ item.name }}
              </a>
              <a
                boost
                *ngIf="item.name === 'Calls'"
                (click)="onSetTitle(1, ''); routeChange(item.url)"
              >
                {{ item.name }}
              </a>
            </li>
          </ul>
        </li>

        <li
          *ngIf="manageContentMenu"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(2, '')"
        >
          <a> Manage Content </a>
          <ul class="sub-menu">
            <li
              routerLinkActive="sub-active"
              *ngFor="let item of manageContentMenu.menu.items"
            >
              <a
                *ngIf="item.name.toLowerCase() != 're-publish website'"
                (click)="routeChange(item.url)"
              >
                {{ item.name }}
              </a>

              <a
                style="cursor: pointer"
                *ngIf="item.name.toLowerCase() == 're-publish website'"
                (click)="openRepublishWebsite()"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </li>
        <li
          *ngIf="manageReports"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(3, '')"
        >
          <a> My Reports </a>
          <ul class="sub-menu">
            <li
              routerLinkActive="sub-active"
              *ngFor="let item of manageReports.menu.items"
            >
              <a (click)="routeChange(item.url)">
                {{ item.name }}
              </a>
            </li>
            <!-- <li>
            <a href="manage-report/sales" (click)="onRouteChange('myReports')">
              Sales
            </a>
          </li> -->
          </ul>
        </li>
        <li
          *ngIf="manageMarketPlace"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(4, '')"
        >
          <a (click)="routeChange('/addon-marketplace/marketplace')">
            Add-Ons Marketplace
            <i class="fa fa-caret-down"></i>
          </a>
          <!-- <ul class="sub-menu">
          <li *ngFor="let item of manageMarketPlace.menu.items">
            <a
              [ngClass]="activeSubTab == item.name ? 'sub-active' : 'none'"
              [routerLink]="item.url"
              (click)="onRouteChange('Marketplace', item.name)"
            >
              {{ item.name }}
            </a>
          </li>
        </ul> -->
        </li>
        <li
          *ngIf="manageSettings"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(5, '')"
        >
          <a>
            Settings
            <i class="fa fa-caret-down"></i>
          </a>
          <ul class="sub-menu">
            <li
              *ngFor="let item of manageSettings.menu.items"
              [routerLinkActive]="
                item.url != 'manage-content/content-sharing-setting'
                  ? 'sub-active'
                  : ''
              "
            >
              <a
                *ngIf="
                  item.name.toLowerCase() !== 'domain & email' &&
                  item.name.toLowerCase() !== 'logout'
                "
                (click)="routeChange(item.url)"
              >
                {{ item.name }}
              </a>

              <a
                *ngIf="item.name.toLowerCase() === 'domain & email'"
                class="cursor-pointer"
                (click)="openDomainEmail()"
                >{{ item.name }}</a
              >
              <a
                *ngIf="item.name.toLowerCase() === 'logout'"
                (click)="onLogout()"
                class="cursor-pointer"
                >{{ item.name }}
              </a>
            </li>
          </ul>
        </li>

        <li class="zeroth-case-health-check pull-right">
          <div style="position: relative">
            <span id="site-meter-percentage">{{ drs_total }}%</span>
            <!-- <progress id="file" value="32" max="100"> 90% </progress> -->
            <mat-progress-bar
              class="progress-bar"
              mode="determinate"
              [value]="drs_total"
            ></mat-progress-bar>
          </div>
        </li>
      </ul>
    </div>

    <div class="nav-menu-responsive" *ngIf="isHumbergerMenu">
      <ul class="responsive-menu-container">
        <li class="boost-heading">
          <div id="brand">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="88"
              height="19"
              viewBox="0 0 88 19"
              fill="none"
              data-bind="attr: {'visibility': 'true' == 'true' ? 'visible': 'hidden'}"
              visibility="visible"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.38895 15.6474C8.14322 15.6474 8.73461 15.5116 9.16388 15.2385C9.59278 14.9662 9.80723 14.543 9.80723 13.9682V13.9242C9.80723 13.4081 9.60715 13 9.2081 12.6976C8.80867 12.3951 8.16533 12.2443 7.27768 12.2443H3.3284V15.6474H7.38895ZM6.4792 9.41595C7.23346 9.41595 7.8326 9.28639 8.27623 9.02837C8.72024 8.77071 8.94243 8.35487 8.94243 7.7801V7.73532C8.94243 7.22001 8.74935 6.82618 8.36504 6.55348C7.97998 6.28114 7.42543 6.14461 6.70102 6.14461H3.3284V9.41595H6.4792ZM0 3.16064H7.19846C8.9848 3.16064 10.3364 3.61723 11.2516 4.53114C11.9602 5.23804 12.3143 6.11488 12.3143 7.16091V7.20533C12.3143 8.07482 12.1157 8.77071 11.7192 9.29336C11.3224 9.81712 10.8448 10.2333 10.2866 10.5427C10.728 10.7193 11.1293 10.9182 11.49 11.1395C11.8508 11.3601 12.1562 11.6218 12.4064 11.9242C12.6566 12.2256 12.8486 12.5761 12.9809 12.9739C13.1128 13.3718 13.1799 13.8284 13.1799 14.3437V14.3885C13.1799 15.0958 13.0431 15.7142 12.7697 16.2446C12.4971 16.7753 12.1058 17.2176 11.5954 17.5706C11.0862 17.9245 10.4734 18.1898 9.75749 18.3664C9.04118 18.5429 8.24749 18.6314 7.37643 18.6314H0V3.16064Z"
                fill="white"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.9036 15.8209C23.5976 15.8209 24.2286 15.6949 24.7975 15.4406C25.3653 15.1863 25.8525 14.8369 26.2585 14.3882C26.6645 13.9409 26.9819 13.4223 27.2106 12.8323C27.4397 12.2427 27.5541 11.613 27.5541 10.9408V10.8958C27.5541 10.2248 27.4397 9.59019 27.2106 8.9935C26.9819 8.39682 26.6572 7.87412 26.2365 7.42689C25.8158 6.97817 25.3212 6.62388 24.7526 6.36253C24.1848 6.1023 23.5531 5.97143 22.8599 5.97143C22.1807 5.97143 21.5529 6.09783 20.9774 6.35175C20.4019 6.60566 19.911 6.95587 19.505 7.40421C19.0986 7.85182 18.7816 8.3708 18.5529 8.95967C18.3234 9.54929 18.209 10.1791 18.209 10.8512V10.8958C18.209 11.5676 18.3234 12.2018 18.5529 12.7985C18.7816 13.3963 19.1063 13.9183 19.527 14.3655C19.9477 14.8135 20.4456 15.1685 21.0219 15.4299C21.5978 15.6905 22.2248 15.8209 22.9036 15.8209ZM22.8599 18.9999C21.664 18.9999 20.5641 18.7873 19.5605 18.3616C18.5562 17.9367 17.6891 17.3623 16.9587 16.6381C16.2277 15.9139 15.6591 15.0637 15.2531 14.0863C14.8468 13.1089 14.6445 12.0606 14.6445 10.9408V10.8958C14.6445 9.77681 14.8512 8.72844 15.2645 7.75144C15.6775 6.7737 16.2497 5.9153 16.9804 5.17623C17.7111 4.43791 18.5819 3.85573 19.5936 3.4308C20.6041 3.00513 21.7081 2.79248 22.9036 2.79248C24.0995 2.79248 25.1994 3.00513 26.2034 3.4308C27.2073 3.85573 28.0744 4.4301 28.8051 5.15393C29.5358 5.87812 30.1044 6.72872 30.5104 7.70608C30.916 8.68382 31.1193 9.73183 31.1193 10.8512V10.8958C31.1193 12.0152 30.9123 13.0636 30.4993 14.0409C30.0856 15.0187 29.5141 15.8767 28.7834 16.6154C28.0524 17.3545 27.1812 17.9367 26.1703 18.3616C25.1586 18.7873 24.0558 18.9999 22.8599 18.9999Z"
                fill="white"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.2085 15.8209C41.9021 15.8209 42.5334 15.6949 43.102 15.4406C43.6705 15.1863 44.157 14.8369 44.5633 14.3882C44.9693 13.9409 45.2863 13.4223 45.5154 12.8323C45.7441 12.2427 45.8585 11.613 45.8585 10.9408V10.8958C45.8585 10.2248 45.7441 9.59019 45.5154 8.9935C45.2863 8.39682 44.9616 7.87412 44.5413 7.42689C44.1209 6.97817 43.6252 6.62388 43.0575 6.36253C42.4893 6.1023 41.8579 5.97143 41.1647 5.97143C40.4848 5.97143 39.8582 6.09783 39.2819 6.35175C38.7064 6.60566 38.2155 6.95587 37.8095 7.40421C37.4036 7.85182 37.0862 8.3708 36.8575 8.95967C36.6288 9.54929 36.514 10.1791 36.514 10.8512V10.8958C36.514 11.5676 36.6288 12.2018 36.8575 12.7985C37.0862 13.3963 37.4109 13.9183 37.8316 14.3655C38.2527 14.8135 38.7506 15.1685 39.3268 15.4299C39.9023 15.6905 40.5297 15.8209 41.2085 15.8209ZM41.1647 18.9999C39.9689 18.9999 38.8686 18.7873 37.8647 18.3616C36.8608 17.9367 35.994 17.3623 35.2634 16.6381C34.5323 15.9139 33.9638 15.0637 33.5582 14.0863C33.1526 13.1089 32.9492 12.0606 32.9492 10.9408V10.8958C32.9492 9.77681 33.1559 8.72844 33.5692 7.75144C33.9825 6.7737 34.5544 5.9153 35.2851 5.17623C36.0157 4.43791 36.8865 3.85573 37.8982 3.4308C38.9091 3.00513 40.013 2.79248 41.2085 2.79248C42.4047 2.79248 43.5039 3.00513 44.5082 3.4308C45.5117 3.85573 46.3796 4.4301 47.1103 5.15393C47.8402 5.87812 48.4091 6.72872 48.8147 7.70608C49.2203 8.68382 49.424 9.73183 49.424 10.8512V10.8958C49.424 12.0152 49.217 13.0636 48.804 14.0409C48.3903 15.0187 47.8185 15.8767 47.0878 16.6154C46.3568 17.3545 45.486 17.9367 44.4743 18.3616C43.4634 18.7873 42.3602 18.9999 41.1647 18.9999Z"
                fill="white"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.0106 18.9997C55.8456 18.9997 54.7024 18.7995 53.5806 18.3995C52.4595 17.9998 51.4404 17.3842 50.5225 16.5545L52.5103 14.1604C53.2098 14.7321 53.9267 15.1866 54.6625 15.5242C55.3979 15.8614 56.203 16.0302 57.077 16.0302C57.7757 16.0302 58.3178 15.9016 58.7038 15.6444C59.0902 15.3876 59.2828 15.031 59.2828 14.5758V14.5319C59.2828 14.3109 59.2429 14.1165 59.1627 13.9478C59.0818 13.779 58.9287 13.6208 58.7023 13.4735C58.476 13.3263 58.1621 13.1798 57.7601 13.0333C57.3585 12.8864 56.8371 12.7315 56.1942 12.57C55.42 12.3647 54.7194 12.1477 54.091 11.9201C53.463 11.6925 52.9301 11.4057 52.4925 11.0608C52.0539 10.7156 51.7147 10.2859 51.4734 9.77225C51.2325 9.25712 51.1124 8.6174 51.1124 7.8542V7.81C51.1124 7.10525 51.243 6.46955 51.5031 5.904C51.7629 5.33845 52.1286 4.84999 52.5988 4.43825C53.0694 4.02724 53.631 3.71122 54.2841 3.49055C54.936 3.27025 55.6566 3.16064 56.4428 3.16064C57.5642 3.16064 58.595 3.32761 59.5343 3.66226C60.4732 3.99728 61.3371 4.47844 62.1233 5.10463L60.3756 7.64851C59.6906 7.17941 59.0209 6.80896 58.3657 6.53751C57.7101 6.26606 57.0548 6.13015 56.3992 6.13015C55.7436 6.13015 55.252 6.25839 54.9244 6.51522C54.5972 6.77279 54.4332 7.09246 54.4332 7.47461V7.51882C54.4332 7.76835 54.4807 7.985 54.5754 8.16876C54.6705 8.35217 54.8421 8.51767 55.0899 8.66417C55.338 8.8114 55.6776 8.95133 56.1086 9.08322C56.5389 9.21547 57.0831 9.37038 57.7409 9.54611C58.5151 9.75179 59.2048 9.98342 59.8107 10.2403C60.4166 10.4975 60.9278 10.8058 61.344 11.1657C61.7597 11.5259 62.0743 11.9486 62.2862 12.4327C62.4973 12.9175 62.604 13.498 62.604 14.1735V14.2177C62.604 14.9817 62.4661 15.6612 62.1915 16.256C61.9165 16.8519 61.533 17.3513 61.041 17.7554C60.5487 18.1587 59.9613 18.4681 59.2796 18.6808C58.5971 18.8934 57.841 18.9997 57.0106 18.9997Z"
                fill="white"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M68.0839 6.35606H63.3882V3.20703H76.1741V6.35606H71.4784V18.7334H68.0839V6.35606Z"
                fill="white"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.9579 5.94888L86.2724 5.21226L87.4478 5.86767C87.025 8.50454 84.6176 10.3753 81.947 10.0855C79.1873 9.78608 77.1912 7.29198 77.4889 4.51535C77.7869 1.73836 80.2654 -0.269888 83.0251 0.0295809C85.4529 0.29332 87.2896 2.2545 87.494 4.61281L86.1389 3.89333L85.0459 4.46865C84.8031 3.40091 83.911 2.55613 82.7678 2.43196C81.3264 2.27541 80.0322 3.32411 79.8766 4.7743C79.721 6.2245 80.7633 7.52699 82.2047 7.68354C83.4352 7.81687 84.5588 7.07202 84.9579 5.94888Z"
                fill="var(--primary-color)"
              ></path>
            </svg>
            <svg
              (click)="onHamburgerClick()"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              data-bind="click: $root.toggleNavigationMobile"
            >
              <rect
                x="4.5498"
                y="15.0503"
                width="14.8492"
                height="1.9799"
                rx="0.98995"
                transform="rotate(-45 4.5498 15.0503)"
                fill="white"
              ></rect>
              <rect
                x="15.0496"
                y="16.4502"
                width="14.8492"
                height="1.9799"
                rx="0.98995"
                transform="rotate(-135 15.0496 16.4502)"
                fill="white"
              ></rect>
            </svg>
          </div>
        </li>

        <li
          routerLinkActive="active"
          (click)="onSetTitle(0, 'DASHBOARD'); onHamburgerClick()"
        >
          <a (click)="routeChange('/dashboard')"> Dashboard </a>
        </li>
        <li
          *ngIf="manageUserMenu"
          class="has-sub-menu"
          (click)="onSetTitle(1, 'MANAGE_CUSTOMER')"
        >
          <div class="w-full">
            <a>
              Manage&nbsp;<span>{{ manageUserMenu.menu.name }}</span>
            </a>
            <mat-icon class="downDropArrow">arrow_drop_down</mat-icon>
          </div>
        </li>
        <div>
          <ul
            class="responsive-sub-menu"
            *ngIf="menuHeader == 'MANAGE_CUSTOMER'"
          >
            <li
              *ngFor="let item of manageUserMenu.menu.items"
              (click)="onHamburgerClick()"
            >
              <a (click)="routeChange(item.url)">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
        <li
          *ngIf="manageContentMenu"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(2, 'MANAGE_CONTENT')"
        >
          <div class="w-full">
            <a> Manage Content </a>
            <mat-icon class="downDropArrow">arrow_drop_down</mat-icon>
          </div>
          <div>
            <ul
              class="responsive-sub-menu"
              *ngIf="menuHeader == 'MANAGE_CONTENT'"
            >
              <li
                *ngFor="let item of manageContentMenu.menu.items"
                (click)="onHamburgerClick()"
              >
                <a
                  *ngIf="item.name.toLowerCase() != 're-publish website'"
                  (click)="routeChange(item.url)"
                >
                  {{ item.name }}
                </a>

                <a
                  style="cursor: pointer"
                  *ngIf="item.name.toLowerCase() == 're-publish website'"
                  (click)="openRepublishWebsite()"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
          </div>
        </li>
        <li
          *ngIf="manageReports"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(3, 'REPORTS')"
        >
          <div class="w-full">
            <a> My Reports </a>
            <mat-icon class="downDropArrow">arrow_drop_down</mat-icon>
          </div>
          <div>
            <ul class="responsive-sub-menu" *ngIf="menuHeader == 'REPORTS'">
              <li
                *ngFor="let item of manageReports.menu.items"
                (click)="onHamburgerClick()"
              >
                <a (click)="routeChange(item.url)">
                  {{ item.name }}
                </a>
              </li>
              <!-- <li>
        <a href="manage-report/sales" (click)="onRouteChange('myReports')">
          Sales
        </a>
      </li> -->
            </ul>
          </div>
        </li>
        <li
          *ngIf="manageMarketPlace"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(4, 'MARKETPLACE'); onHamburgerClick()"
        >
          <a (click)="routeChange('/addon-marketplace/marketplace')">
            Add-Ons Marketplace
            <i class="fa fa-caret-down"></i>
          </a>
          <!-- <ul class="responsive-sub-menu">
      <li *ngFor="let item of manageMarketPlace.menu.items">
        <a
          [ngClass]="activeSubTab == item.name ? 'sub-active' : 'none'"
          [routerLink]="item.url"
          (click)="onRouteChange('Marketplace', item.name)"
        >
          {{ item.name }}
        </a>
      </li>
    </ul> -->
        </li>
        <li
          *ngIf="manageSettings"
          class="has-sub-menu"
          routerLinkActive="active"
          (click)="onSetTitle(5, 'SETTING')"
        >
          <a> Settings </a>
          <mat-icon class="downDropArrow">arrow_drop_down</mat-icon>
          <ul class="responsive-sub-menu" *ngIf="menuHeader == 'SETTING'">
            <li
              *ngFor="let item of manageSettings.menu.items"
              (click)="onHamburgerClick()"
              [routerLinkActive]="
                item.url != 'manage-content/content-sharing-setting'
                  ? 'sub-active'
                  : ''
              "
            >
              <a
                *ngIf="
                  item.name.toLowerCase() !== 'domain & email' &&
                  item.name.toLowerCase() !== 'logout'
                "
                (click)="routeChange(item.url)"
              >
                {{ item.name }}
              </a>

              <a
                *ngIf="item.name.toLowerCase() === 'domain & email'"
                class="cursor-pointer"
                (click)="openDomainEmail()"
                >{{ item.name }}</a
              >
              <a
                *ngIf="item.name.toLowerCase() === 'logout'"
                (click)="onLogout()"
                class="cursor-pointer"
                >{{ item.name }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

  <main-mlc-header
    class="navbar navbar-default navbar-fixed-top header-fixed primary-font"
    *ngIf="(loginService.isLoggedIn$ | async) && isMLC"
  ></main-mlc-header>

  <div [ngClass]="{ 'pt-28': boostActive, 'pt-44': jioActive }">
    <router-outlet></router-outlet>
  </div>
</div>
<div
  class="footer-wrapper"
  *ngIf="
    (loginService.isLoggedIn$ | async) &&
    !(mlcService.mlcLoader$ | async) &&
    !mlcService.isMLC
  "
>
  <div class="footer-container">
    <div boost class="nowFloatPvt">
      © 2022 NowFloats Technologies Pvt. Ltd. - All rights reserved.
    </div>
    <div jio-online class="nowFloatPvt">
      © 2022 Reliance Jio Infocomm Ltd. All rights reserved
    </div>
    <div class="contactUsWrap">
      <a href="https://www.nowfloats.com/tnc" class="pull-right">T&amp;C</a>
      <a href="https://www.nowfloats.com/privacy" class="pull-right">Policy</a>
      <a (click)="openNeedHelpModal()">Contact us</a>
    </div>
  </div>
</div>
